import React, { useState, useEffect } from 'react';
import { Admin } from 'react-admin';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';

import { Popup } from '../../components';
import './Administration.scss';

function Administration() {
  const [popup, setPopup] = useState({trigger: false, title: '', description: ''});
  const [loginInfo, setLoginInfo] = useState({'uuid': '', 'username': '', 'role': ''});
  const [isUserLoggedIn, setIsUserLoggedIn] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    checkLogin()
  }, [])

  const checkLogin = () => {
    axios.get('api/checkLogin.php')
    .then(function(response) {
      if (response.status === 200) {
        if (response.data.logged === true && response.data.role === 'admin') {
          setLoginInfo({'uuid': response.data.uuid, 'username': response.data.username, 'role': response.data.role});
          setIsUserLoggedIn(true);
        } else {
          navigate('/admin/login');
        }
      }
    })
    .catch(function(error) {
      setPopup({'trigger': true, 'title': 'Si è verificato un errore!', 'description': 'Si è verificato un errore generico.'});
    })
  }

  const closePopup = () => {
    setPopup({...popup, 'trigger': false});
  }

  const AdminPanel = () => {

  }

  return (
    <>
      {isUserLoggedIn && (
        <Admin />
      )}
      <Popup trigger={popup['trigger']} title={popup['title']} description={popup['description']} onClick={closePopup} />
    </>
  )
}

export default Administration